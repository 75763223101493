import * as React from 'react';
import { Backdrop, Box, Modal, Fade, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Descope } from '@descope/react-sdk';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "430px",
    width: "90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const SignUp = ({ isSignUpOpen, setIsSignUpOpen }) => {

    const handleClose = () => setIsSignUpOpen(false);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isSignUpOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isSignUpOpen}>
                <Box sx={style}>
                    <Box sx={{ position: 'absolute', top: "5px", right: "5px", zIndex: "999", display: 'inline-flex' }}>
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    <Descope
                        flowId="sign-up-or-in"
                        theme="light"
                        onSuccess={(e) => {
                            // console.log(e);
                            // console.log(e.detail.user.name)
                            // console.log(e.detail.user.email)
                            setIsSignUpOpen(false);
                        }}
                        onError={(err) => {
                            console.log("Error!", err)
                        }}
                    // redirectAfterSuccess="/"
                    // redirectUrl='https://ai2humanize.com/'
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default SignUp
