import React, { useRef, useState } from "react";
import { Container, Typography, Box, Grid2, Button } from "@mui/material";
import { LoremIpsum } from "lorem-ipsum";
import UserEditorSection from "./UserEditorSection";
import AutoAIEditorSection from "./AutoAIEditorSection";
import simpleText from "../../../assets/images/sample_text.svg";
import pastText from "../../../assets/images/paste_text.svg";
import greenstar from "../../../assets/images/white-star.svg";
import frameIcon from "../../../assets/images/frame_logo.svg";
// import StepperSection from "./StepperSection";
// import { GoogleReCaptchaProvider } from "@google-recaptcha/react";
// import { GoogleReCaptchaCheckbox } from "@google-recaptcha/react";
import iicon from "../../../assets/images/i-icon.svg";
import Slider from '@mui/material/Slider';
import EditorExpandModal from "../../modals/EditorExpandModal";
import Notification from "../NotificationPopup/Notification";
// import { ToastAction } from "../../../redux/Actions/ToastAction";
// import { useDispatch, useSelector } from "react-redux";

// Create an instance of LoremIpsum
const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    min: 5,
    max: 10,
  },
  wordsPerSentence: {
    min: 5,
    max: 15,
  },
});

const marks = [
  {
    value: 0,
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
  },
];

const AIbannerSection = () => {
  const [textEditorContent, setTextEditorContent] = useState("");
  const [secondEditorContent, setSecondEditorContent] = useState("");
  const [markValue, setMarkValue] = useState(50);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [humanCaptcha, setHumanCaptcha] = useState(false);

  const quillRef = useRef(null);
  // const toast = useSelector(state => state);
  // const dispatch = useDispatch();

  // console.log("toast", toast);

  // useEffect(() => {
  //   dispatch(ToastAction({ hide: true }))
  // }, [])

  // console.log("textEditorContent", typeof textEditorContent, textEditorContent);
  // console.log("secondEditorContent", typeof secondEditorContent, secondEditorContent);

  const valuetext = (value) => {
    switch (value) {
      case 10:
        value = 0.1;
        break;
      case 20:
        value = 0.2;
        break;
      case 30:
        value = 0.3;
        break;
      case 40:
        value = 0.4;
        break;
      case 50:
        value = 0.5;
        break;
      case 60:
        value = 0.6;
        break;
      case 70:
        value = 0.7;
        break;
      case 80:
        value = 0.8;
        break;
      case 90:
        value = 0.9;
        break;
      case 100:
        value = 1;
        break;
      default:
        break;
    }
    setMarkValue(value)
    // console.log(value);
  }

  // const humanizeContentHandler = () => {
  //   setSecondEditorContent(textEditorContent)
  // }

  const simple_To_Humanize = async () => {
    if (markValue > 0.5) {
      setNotificationOpen(true)
      setNotificationMessage('Please get premium');
    }
    else {
      try {
        const response = await fetch("https://api.ai2humanize.com/process", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            text: textEditorContent,
            humanizer_level: markValue
          }),
        });

        if (!response.ok) {
          throw new Error("An error occurred while processing the text");
        }

        const data = await response.json();
        // console.log("data", data);

        // const humanizeOutput = data?.processed_text?.replace(/<\s*(\w+)\s*>/g, '<$1>').replace(/<\/\s*(\w+)\s*>/g, '</$1>');
        const humanizeOutput = data?.processed_text
          ?.replace(/<\s*(\w+)\s*>/g, '<$1>')  // Remove spaces in opening tags
          .replace(/<\/\s*(\w+)\s*>/g, '</$1>') // Remove spaces in closing tags
          .replace(/<img[^>]*>\s*/g, '')      // Remove <img> tags
          .replace(/<\s*\/?\s*img[^>]*>/g, '');

        setSecondEditorContent(humanizeOutput);

        if (!textEditorContent || textEditorContent === "<p><br></p>") {
          setNotificationOpen(true)
          setNotificationMessage('Please enter text');
        }
        else {
          setNotificationOpen(true)
          setNotificationMessage('Humanize successfully');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const insertContent = (content) => {
    const editor = quillRef?.current?.getEditor();
    const currentIndex = editor?.getSelection()?.index || 0;
    editor?.clipboard?.dangerouslyPasteHTML(currentIndex, content);
    // editor?.setSelection(currentIndex, content?.length)
  };

  // Function to strip HTML tags (for plain text extraction)
  // const stripHTMLTags = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };

  const generateRandomParagraph = () => {
    const newRandomParagraph = lorem?.generateParagraphs(1); // Generate 1 randomParagraph
    insertContent(newRandomParagraph);
  };

  const getClipboardContent = async () => {
    try {
      const clipboardText = await navigator?.clipboard?.readText();
      console.log("getClipboardContent", clipboardText);
      insertContent(clipboardText);
    } catch (err) {
      console.error("Failed to read clipboard contents:", err);
    }
  };

  return (
    <>
      {" "}
      <Box className="AIbannerSection">
        <Container className="customContainer">
          <Grid2 container alignItems="center">
            <Grid2 size={{ xs: 12 }}>
              <Box textAlign="center" className="AIbannerTopSection">
                <Typography variant="h1">
                  Most Accurate
                  <Typography variant="span">Text Humanizer</Typography>,<br />{" "}
                  Transform AI Text Into Human Content
                </Typography>
                {/* <Typography>
                  Generate human-like, undetectable writing with Humanizery by transforming AI-generated text into more authentic content using our AI to Human technology. Our AI to Human Text process ensures your content is engaging, readable, and free from a robotic tone. Plagiarism-free guaranteed!
                </Typography> */}
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Box className="editorSection">

          {/* <Container maxWidth="xl" className="mobile_padding"> */}
          <Box className="mobile_padding">
            <Box className="main_editorbox">
              <Box sx={{ flexGrow: 1 }} className="editorbox">
                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Box>
                      <Box className="editorbox_bg1">
                        <UserEditorSection
                          textEditorContent={textEditorContent}
                          setTextEditorContent={setTextEditorContent}
                          quillRef={quillRef}
                          handleOpen={handleOpen}
                        />
                        {
                          (!textEditorContent || textEditorContent === "<p><br></p>") && (
                            <Box className="editor_box_info">
                              <Button
                                className="sample_box"
                                onClick={generateRandomParagraph}
                              >
                                <img src={simpleText} alt="" />
                                <Typography component="p">Sample Text</Typography>
                              </Button>
                              <Button className="past_box" onClick={getClipboardContent}>
                                <img src={pastText} alt="" />
                                <Typography component="p">Paste Text</Typography>
                              </Button>
                            </Box>
                          )
                        }
                      </Box>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Box className="editorbox_bg2">
                      <AutoAIEditorSection
                        key={secondEditorContent}
                        // quillRef={quillRef}
                        secondEditorContent={secondEditorContent}
                      />
                    </Box>
                  </Grid2>
                </Grid2>
                <Box className="frame_img">
                  <img src={frameIcon} alt="frameIcon" />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* </Container> */}

        </Box>
        <Container>
          <Box className='slider_section'>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={iicon} alt="iicon" />
              Level
            </Typography>
            <Slider
              sx={{
                maxWidth: "535px",
                margin: "auto",
                "& .MuiSlider-rail": {
                  height: "11px",
                },
                "& .MuiSlider-track": {
                  height: "11px",
                },
                "& .MuiSlider-thumb": {
                  width: "49px",
                  height: "29px",
                  borderRadius: "14.5px",
                  boxShadow: "0px 0px 29px 0px #00af62 !important",
                  backdropFilter: " blur(29px)"
                },
                "& .MuiSlider-mark": {
                  width: "3px",
                  height: "3px",
                  borderRadius: "50%",
                  color: " #fff"
                },
                "& .MuiSlider-valueLabel::before": {
                  width: "16px",
                  height: "14px"
                },
              }}
              aria-label="Always visible"
              defaultValue={markValue}
              getAriaValueText={valuetext}
              step={10}
              marks={marks}
              valueLabelDisplay="on"
            />
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={iicon} alt="iicon" />
              More Human
            </Typography>
          </Box>
          <Box className="captcha_section">

            {/* {
                !humanCaptcha && <Box className="google-humanCaptcha">
                  <GoogleReCaptchaProvider
                    type="v2-checkbox"
                    siteKey="6LfoCEgqAAAAALD59azjmFC8Ygftf2_FcEbTUAR2"
                  >
                    <GoogleReCaptchaCheckbox
                      onChange={(token) => {
                        setHumanCaptcha(!humanCaptcha)
                      }}
                    />
                  </GoogleReCaptchaProvider>
                </Box>
              } */}
            <Box className="humanize-btn">
              {/* {!humanCaptcha ? <Button className="editor_btn" disabled>
                  Humanize <img src={greenstar} alt="Humanize" />
                </Button> : */}
              <Button className="editor_btn" onClick={simple_To_Humanize}>
                Humanize <img src={greenstar} alt="Humanize" />
              </Button>
            </Box>
          </Box>
          {/* <Box>
            <StepperSection />
          </Box> */}
        </Container>
        <Container className="customContainer">
          <EditorExpandModal open={open} handleOpen={handleOpen} handleClose={handleClose} >
            <UserEditorSection
              // key={textEditorContent}
              textEditorContent={textEditorContent}
              setTextEditorContent={setTextEditorContent}
              quillRef={quillRef}
              handleOpen={handleOpen}
            />
          </EditorExpandModal>
        </Container>
        <Notification notificationOpen={notificationOpen} setNotificationOpen={setNotificationOpen} notificationMessage={notificationMessage} />
      </Box>
    </>
  );
};

export default AIbannerSection;
