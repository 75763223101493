import React from "react";
import {
    Container,
    Typography,
    Box,
    Grid2,
    AccordionDetails,
    Accordion,
    AccordionSummary,
} from "@mui/material";
import LongSquare from "../../../assets/icons/longsquare.svg";

const faqQuestionAnswer = [
    {
        question: "Is Humanize AI free to use?",
        answer: "Humanize AI offers a free plan for basic mode rewriting, allowing up to 500 words. For extended usage, subscription plans are available with both yearly and monthly options."
    },
    {
        question: "Can Humanize AI produce fully human-like text?",
        answer: "Yes, Humnize AI is designed to generate text that closely resembles human writing, making it undetectable by most AI detectors."
    },
    {
        question: "What are the benefits of using Humnize AI?",
        answer: "Humnize AI is ideal for marketers, SEO specialists, writers, bloggers, journalists, and researchers. It helps users avoid issues related to AI detection and false accusations of using AI-generated content. The tool encourages the use of AI while protecting users with its advanced bypass technology"
    },
    {
        question: "How does the Humanize AI algorithm work?",
        answer: "The algorithm is designed to rephrase and rewrite content in a way that Yes, the tool is designed to generate text that resembles natural human language, making it undetectable and hard to tell apart from human-written content human writing patterns, ensuring the text appears natural and undetectable by AI detection systems.It uses advanced language models to achieve this"
    },
    {
        question: "Is the SEO value of my content retained?",
        answer: "Yes, Humnize AI is built to retain the SEO value of your content by ensuring that keywords and optimization factors remain intact during rewriting."
    },
    {
        question: "Can the AI text converter bypass all AI detectors?",
        answer: "Humnize AI is equipped with state-of-the-art bypass technology that allows it to avoid detection by most AI detectors, making your content appear fully human-generated."
    },
    {
        question: "Can Humanize AI really produce fully human-like text?",
        answer: "Yes, the tool is designed to generate text that produce resembles human language, making it undetectable from human-written content."
    },
]

const FaqSection = () => {
    return (
        <Box className="faqSection">
            <Container>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box className="faqSectionTop">
                            <Typography variant="h2">
                                Frequently Asked Questions
                            </Typography>
                            <Typography>
                                Boost Productivity with Saasify-Collaborate, Manage
                                <br /> Projects and Achieve more than any Workspace!
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
            <Container>
                <Grid2 container>
                    <Grid2 size={{ xs: 12, md: 12 }}>
                        <Box
                            className="faqSectionBottom"
                            sx={{
                                "& .MuiAccordion-root.Mui-expanded": {
                                    background: "#1B8473",
                                    "& .faqSectionTitle": {
                                        color: "#fff",
                                    },
                                    "& .faqSectionContent": {
                                        color: "#fff",
                                    },
                                    "& img": {
                                        opacity: "0",
                                    },
                                },
                            }}
                        >
                            {
                                faqQuestionAnswer?.map((faqQueAns, index) => {
                                    return <Accordion
                                        key={faqQueAns?.question}
                                        sx={{
                                            minHeight: { md: "90px", xs: "66px" },
                                            background: "#F6F6F6",
                                            borderRadius: "13.28px !important",
                                            padding: { md: "17px 30px", xs: "10px" },
                                            boxSizing: "border-box",
                                            cursor: "pointer",
                                            boxShadow: "none",
                                        }}
                                    >
                                        <AccordionSummary
                                            aria-controls={`panel${index}a-content`}
                                            id={`panel${index}a-header`}
                                        >
                                            <Typography className="faqSectionTitle">
                                                {faqQueAns?.question}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <img src={LongSquare} alt="" />
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="faqSectionContent">
                                                {faqQueAns?.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }
                        </Box>
                    </Grid2>
                </Grid2>
            </Container>
        </Box>
    )
}

export default FaqSection