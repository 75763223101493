import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const countries = [
    { code: 'sq', label: 'Albanian' },
    { code: 'af', label: 'Afrikaans' },
    { code: 'am', label: 'Amharic' },
    { code: 'grc', label: 'Ancient Greek' },
    { code: 'ar', label: 'Arabic' },
    { code: 'hy', label: 'Armenian' },
    { code: 'az', label: 'Azerbaijani' },
    { code: 'eu', label: 'Basque' },
    { code: 'bn', label: 'Bengali' },
    { code: 'bg', label: 'Bulgarian' },
    { code: 'ca', label: 'Catalan' },
    { code: 'zh', label: 'Chinese' },
    { code: 'hr', label: 'Croatian' },
    { code: 'cs', label: 'Czech' },
    { code: 'da', label: 'Danish' },
    { code: 'nl', label: 'Dutch' },
    { code: 'en', label: 'English' },
    { code: 'et', label: 'Estonian' },
    { code: 'fo', label: 'Faroese' },
    { code: 'fi', label: 'Finnish' },
    { code: 'fr', label: 'French' },
    { code: 'de', label: 'German' },
    { code: 'el', label: 'Greek' },
    { code: 'gu', label: 'Gujarati' },
    { code: 'he', label: 'Hebrew' },
    { code: 'hi', label: 'Hindi' },
    { code: 'hu', label: 'Hungarian' },
    { code: 'is', label: 'Icelandic' },
    { code: 'id', label: 'Indonesian' },
    { code: 'ga', label: 'Irish' },
    { code: 'it', label: 'Italian' },
    { code: 'ja', label: 'Japanese' },
    { code: 'kn', label: 'Kannada' },
    { code: 'ko', label: 'Korean' },
    { code: 'ky', label: 'Kyrgyz' },
    { code: 'la', label: 'Latin' },
    { code: 'lv', label: 'Latvian' },
    { code: 'lij', label: 'Ligurian' },
    { code: 'lt', label: 'Lithuanian' },
    { code: 'dsb', label: 'Lower Sorbian' },
    { code: 'lg', label: 'Luganda' },
    { code: 'lb', label: 'Luxembourgish' },
    { code: 'mk', label: 'Macedonian' },
    { code: 'ms', label: 'Malay' },
    { code: 'ml', label: 'Malayalam' },
    { code: 'mr', label: 'Marathi' },
    { code: 'xx', label: 'Multi-language' },
    { code: 'ne', label: 'Nepali' },
    { code: 'nb', label: 'Norwegian Bokmål' },
    { code: 'nn', label: 'Norwegian Nynorsk' },
    { code: 'fa', label: 'Persian' },
    { code: 'pl', label: 'Polish' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'ro', label: 'Romanian' },
    { code: 'ru', label: 'Russian' },
    { code: 'sa', label: 'Sanskrit' },
    { code: 'sr', label: 'Serbian' },
    { code: 'tn', label: 'Setswana' },
    { code: 'si', label: 'Sinhala' },
    { code: 'sk', label: 'Slovak' },
    { code: 'sl', label: 'Slovenian' },
    { code: 'es', label: 'Spanish' },
    { code: 'sv', label: 'Swedish' },
    { code: 'tl', label: 'Tagalog' },
    { code: 'ta', label: 'Tamil' },
    { code: 'tt', label: 'Tatar' },
    { code: 'te', label: 'Telugu' },
    { code: 'th', label: 'Thai' },
    { code: 'ti', label: 'Tigrinya' },
    { code: 'tr', label: 'Turkish' },
    { code: 'uk', label: 'Ukrainian' },
    { code: 'hsb', label: 'Upper Sorbian' },
    { code: 'ur', label: 'Urdu' },
    { code: 'vi', label: 'Vietnamese' },
    { code: 'yo', label: 'Yoruba' },
]

const LanguageSelector = () => {
    return (
        <Autocomplete size='small'
          id="country-select-demo"
          sx={{ width: 120 }}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize:"14px" }}
                {...optionProps}
              >
                {/* <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                  alt=""
                /> */}
                {option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="English"
              sx={{'& > label':{fontSize:'15px !important'}}}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                },
              }}
            />
          )}
        />
      );
}

export default LanguageSelector
