// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import store from './redux/store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from './router/router';
import './index.css'
import { AuthProvider } from '@descope/react-sdk';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider projectId='P2nFBtfJzPCL7fwy3C2DWfYFJnPu'>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router}>
            <App />
          </RouterProvider>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
